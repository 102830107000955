body {
  margin: 0;
  background: url("background-min.jpg");
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#App {
  max-width: 1200px;
  margin: auto;
  background-color: rgba(223,222,226,0.88);
  padding: 0 10px;
  min-height: 100vh;
}

#header {
  width: 100%;
  height: 200px;
  //background: url("logo.jpg") no-repeat center;
  /*background-size: contain;*/
  .logo {
    display: block;
    margin: auto;
    max-height: 100px;
  }
  nav {
    display: flex;
    justify-content: space-evenly;
    height: 50px;
    align-items: center;
    div {
      flex: 1;
      background-color: #282c34;
      justify-content: center;
      height: 100%;
      align-items: center;
      display: flex;
      text-align: center;

    }
    a {
      color: white;
      text-decoration: none;
      &.selected {
        color: red;
      }
    }
  }
}

#prices {
  display: grid;
  .price-header {
    display: flex;
    justify-content: space-evenly;
    height: 50px;

    button {
      background-color: black;
      cursor: pointer;
      color: white;
      flex: 1;
      border-radius: 0px;
      border: none;
      &.selected {
        background-color: #625f57;
      }
      &::selection {
        color: transparent;
        background: none;
        border: none;
      }
      &::-moz-selection {
        color: transparent;
        background: none;
      }
    }

  }
  .price-body {
    .row {
      display: flex;
      font-size: 1.2em;
      justify-content: space-between;
      border-bottom: 1px solid rgba(29,27,29,0.78);
      &:hover {
        background-color: rgba(29,27,29,0.51);
      }
      .item {

      }
    }
  }
}